<template>
  <div class="container">
    <div class="handle-box">
      <el-button icon="el-icon-refresh" class="mr10" @click="onRefresh">
        刷新
      </el-button>
      <el-button
        type="success"
        icon="el-icon-plus"
        class="handle-add mr10"
        @click="onAdd"
      >
        添加
      </el-button>
      <el-button
        type="danger"
        icon="el-icon-delete"
        class="handle-del mr10"
        @click="delAll"
      >
        批量删除
      </el-button>
      <el-input
        v-model="query.name"
        class="handle-input mr10"
        placeholder="请输入预约地名"
        clearable
      ></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search">
        搜索
      </el-button>
    </div>
    <!--表格数据-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column type="index" label="序号" width="55" />
      <el-table-column label="编号" prop="code" width="100" />
      <el-table-column label="服务网点" prop="name" />
      <el-table-column label="网点详细地址" prop="address" width="200" />
      <el-table-column label="联系人" prop="linkman" />
      <el-table-column label="手机号码" prop="mobilePhone" />
      <el-table-column label="固定电话" prop="telPhone" />
      <el-table-column label="数量（每日预约人数上限）" prop="nums" />
      <el-table-column label="备注" prop="desciption" />
      <el-table-column label="是否开放" prop="isClose">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isClose"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch($event, scope.row, scope.$index)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑信息"
      width="800px"
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="form" :model="form" :rules="rules" label-width="180px">
          <el-form-item label="编号" prop="code" required>
            <el-input v-model="form.code" type="text" clearable />
          </el-form-item>
          <el-form-item label="预约地名" prop="name" required>
            <el-input v-model="form.name" type="text" clearable />
          </el-form-item>
          <el-form-item
            label="预约详细地址"
            prop="address"
            width="240"
            required
          >
            <el-input v-model="form.address" type="text" clearable />
          </el-form-item>
          <el-form-item label="联系人" prop="linkman" required>
            <el-input v-model="form.linkman" type="text" clearable />
          </el-form-item>
          <el-form-item label="手机号码" prop="mobilePhone" required>
            <el-input v-model="form.mobilePhone" type="number" clearable />
          </el-form-item>
          <el-form-item label="固定电话" prop="telPhone">
            <el-input v-model="form.telPhone" type="number" clearable />
          </el-form-item>
          <el-form-item label="数量" prop="nums" required>
            <el-input-number
              v-model="form.nums"
              :min="0"
              :max="500"
              label="人数"
            ></el-input-number>
            <span class="red">（每日预约人数上限）</span>
          </el-form-item>
          <el-form-item label="备注" prop="desciption">
            <el-input
              v-model="form.desciption"
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDlgShow = false">关 闭</el-button>
        <el-button type="info" plain size="small" @click="resetForm('form')">
          重 置
        </el-button>
        <el-button size="small" type="primary" @click="submitForm('form')">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList, save, del } from '@/api/admin/address';
import Validators from '../../common/validata.js';

export default {
  name: 'Address',
  data() {
    return {
      query: {
        name: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        name: ''
      },
      list: [],
      addressList: [],
      form: {
        id: '',
        name: '',
        code: '',
        address: '',
        linkman: '',
        mobilePhone: '',
        telPhone: '',
        nums: 1,
        isClose: 1,
        desciption: ''
      },
      rules: {
        mobilePhone: [{ validator: Validators.checkPhone, trigger: 'blur' }]
      },
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    changeSwitch(data, b) {
      //开关切换
      save(b).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '状态修改成功',
            type: 'success'
          });
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    search() {
      this.pageInfo.name = this.query.name;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    onAdd() {
      (this.form = {
        id: '',
        name: '',
        code: '',
        address: '',
        linkman: '',
        mobilePhone: '',
        telPhone: '',
        nums: 1,
        isClose: 1,
        desciption: ''
      }),
        (this.selectDlgShow = true);
    },
    onEdit(row) {
      this.form = {
        id: row.id,
        name: row.name,
        code: row.code,
        address: row.address,
        linkman: row.linkman,
        mobilePhone: row.mobilePhone,
        telPhone: row.telPhone,
        nums: row.nums,
        desciption: row.desciption
      };
      this.selectDlgShow = true;
    },
    delAll() {
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        del({
          ids: ids
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    onDel(row) {
      this.$confirm('此操作将永久删除尺寸配置数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del({ id: row.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    fetchData() {
      this.listLoading = true;
      getList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    save() {
      this.dlgLoading = true;
      save(this.form).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: resp.msg,
            type: 'success'
          });
          this.selectDlgShow = false;
          this.dlgLoading = false;
          this.fetchData();
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}
</style>
